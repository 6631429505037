/*Inicio css table */
.table-embelleze-border{
  border: 1px solid #9246A2 !important;
  border-radius: 5px !important;
}

.bg-embelleze{
  background: #9246A2 !important;
  color: #fcfcfc !important;
}

.border-embelleze{
  border-color: #9246A2 !important;
}

.table-embelleze-header{
  background: #9246A2 !important;
  color: #fcfcfc !important;
}

.nav-embelleze.nav-link.active {
  border-bottom: 2px solid #9246A2 !important;
}

.nav-embelleze.nav-link:hover {
  border-bottom: 2px solid #9246A2 !important;
}

.table-embelleze-footer{
  background: #9246A2 !important;
  color: #fcfcfc !important;
}

[data-bs-theme="light"] .table-embelleze-row{
  background-color: #fdfdfd !important;
}

[data-bs-theme="dark"] .table-embelleze-row{
  background-color: #171b26 !important;
}

[data-bs-theme="light"] .table-embelleze-row:hover{
  background-color: #eeeeee !important;
}

[data-bs-theme="dark"] .table-embelleze-row:hover{
  background-color: #2c3241 !important;
}

[data-bs-theme="light"] .table-embelleze-cell{
  color: #2b2b2b !important;
  border-color: #9246A2 !important;
}

[data-bs-theme="dark"] .table-embelleze-cell{
  color: #e4e4e4 !important;
  border-color: #9246A2 !important;
}

.table-embelleze-header-cell{
  color: #e4e4e4 !important;
  border-color: #9246A2 !important;
}

.Mui-active, .MuiTableSortLabel-iconDirectionAsc, .MuiTableSortLabel-iconDirectionDesc {
  color: #e9c7ee !important;
}

.table-embelleze-header-cell .MuiTableSortLabel-root:hover {
  color: #bfa3c3 !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  margin-top: 10px !important;
}

.tabela-embelleze.MuiPaper-root {
  background-color: #171b2600 !important;
}
/*Fim css table */

.input-suffix-iembelleze {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-suffix-iembelleze::after {
  content: '@iembelleze.com';
  width: 11em;
  height: 1.5em;
  position: absolute;
  top: 50%;
  right: 20px;  
  transform: translateY(-50%);
  opacity: 0.4;
  font-weight: bold;
  font-size: 16px;
}