

.layout-background {
  background: linear-gradient(#9246A2, #60269E);
}

[data-bs-theme="light"] .layout-login-background {
  background: rgba(255, 255, 255, 0.9) !important
}

[data-bs-theme="dark"] .layout-login-background {
  background: rgba(39, 33, 51, 0.85) !important
}
